.badge
    display: flex
    align-items: center
    padding: 7px 15px
    margin-right: 8px
    border-radius: 24px
    background: #fff
    border: 1px solid transparent

    &Icon
        display: flex
        margin-right: 4px

// sizes

.sm
    padding: 4px 8px 4px 4px

.smUltra
    font-size: 10px
    line-height: 16px
    padding: 4px 8px

// views
.class
    background-color: var(--red-color)
    color: #fff

.inProgress
    background-color: #CCE6FF
    color: #282A2E
    padding: 4px 16px

.complete
    background-color: #FED1D4
    color: #E71F43

.blackFriday
    background-color: #FE0000
    color: #ffffff
    // border: 1px solid #303438

.blackFridaySecond
    background-color: #000000
    color: #ffffff
    // border: 1px solid #303438

.newYear
    background-color: #F86F6C !important
    color: #fff !important

.shoppingDay
    color: #152B56
    background-color: #00E8F0
    border: 1px solid #152B56

.present
    background-color: #6A4DFF
    color: #F3F6F9
    border: 1px solid #FFCF99

.rewies
    background: rgba(#031228, .75)
    color: #fff

.lux
    border: 1px solid #520183
    background: #520183
    color: #fff

.shipRewievs
    border: 1px solid #007FFD
    background: #007FFD
    color: #fff

.tour
    border: 1px solid #17A0E5
    background: #FFFFFF
    color: #17A0E5


.cashBack
    background: #818894
    color: #fff

.luxSolid
    background: #405967
    color: #fff

.hot
    border: 1px solid var(--red-color)
    color: var(--red-color)

.freeplaces
    border: 1px solid #F96190
    border-radius: 12px
    color: #FD2249
    font-weight: 700
    font-size: 10px
    line-height: 16px
    padding: 4px 8px
    gap: 4px

.freeplacesmb
    color: #FD2249
    font-weight: 700
    font-size: 10px
    line-height: 16px

.shopping
    background-color: #FE0000
    color: #fff
    font-size: 24px
    font-weight: 800
    line-height: 40px

.standart
    border: 1px solid #5872F9
    background: #5872F9
    color: #fff

.comfort
    border: 1px solid #6D51DB
    background: #6D51DB
    color: #fff

.eco
    background: #5CA2E3
    color: #fff

.premium
    background: #7C24B2
    color: #fff

.season
    border: 1px solid var(--darken-color)
    color: var(--darken-color)
    width: max-content

.info
    background: #8BA2C7
    color: #fff

.infoBf
    background: #FFB500
    color: #000000

.white
    background-color: #fff
    color: var(--primary-color)

.company
    background-color: #818894
    color: #fff
    text-transform: uppercase

.gray
    background: rgba(139, 162, 199, 0.2)
    color: #2B3134

@media (max-width: 768px)
    .badge
        justify-content: flex-start
