.select

    &CaretDownIcon
        border: 6px solid transparent
        border-top: 6px solid #000000
        margin-top: 11px
        margin-left: 16px
        display: flex
        align-items: center


        &Disabled
            border-top: 6px solid var(--disabled-color)
        &ReadOnly
            display: none
    &Dropdown
        display: flex
        align-items: center
        justify-content: space-between


    &Placeholder
        will-change: transform
        transition: 0.2s transform
        font-size: 16px
        position: relative
        font-weight: bold


        &Active
            transform: translateX(-10%) translateY(-55%) scale(0.75),
            font-weight: normal
            position: absolute

    .plus
        padding-left: 5px

.itemWrapper
    max-width: 1px
    max-height: 1px
    overflow: hidden

.valueWrapper
    position: relative
    top: 9px

.selectorShape
    white-space: nowrap
    position: relative

