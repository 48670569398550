.overlay
    display: none
    justify-content: center
    align-items: center
    position: fixed
    left: 0
    top: 0
    overflow: auto
    width: 100%
    height: 100%
    z-index: 9998
    background-color: rgba(20, 21, 23, 0.6)

.close
    position: relative
    width: 56px
    height: 56px
    padding: 16px 0 0 0
    gap: 10px
    border-radius: 8px
    background: rgba(243, 246, 249, 1)

    &::before
        content: ''
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%) rotate(45deg)
        display: inline-block
        width: 38px
        height: 38px
        background: linear-gradient(#0281FF,#0281FF),linear-gradient(#0281FF,#0281FF)
        background-position: center
        background-size: 50% 2px, 2px 50%
        background-repeat: no-repeat
        flex-shrink: 0

.closeBf
    display: inline-block
    width: 38px
    height: 38px
    background: linear-gradient(#fff,#fff),linear-gradient(#fff,#fff),#0281FF
    background-position: center
    background-size: 50% 2px, 2px 50%
    background-repeat: no-repeat
    background: linear-gradient(#FFB500,#FFB500),linear-gradient(#FFB500,#FFB500)
    background-position: center
    background-size: 50% 2px, 2px 50%
    background-repeat: no-repeat
    transform: rotate(45deg)
    flex-shrink: 0

.special
    border-radius: 50px
    background-color: rgba(212, 229, 255, 0.4)
    backdrop-filter: blur(42px)
    position: relative
    display: flex
    overflow: auto
    z-index: 9100
    flex-direction: column

    &DisabledOverflow
        overflow: visible !important

.content
    border-radius: 8px
    background-color: #fff
    position: relative
    display: flex
    overflow-y: auto
    z-index: 9100
    flex-direction: column

    &DisabledOverflow
        overflow: visible !important

.modalContent
    overflow-y: auto

.contentBf
    border-radius: 8px
    background-color: #000000
    position: relative
    display: flex
    overflow: auto
    z-index: 9100
    flex-direction: column

    &DisabledOverflow
        overflow: visible !important

.fullScreen
    overflow: auto
    width: 100% !important
    height: 100%
    z-index: 999999 !important
    border-radius: 0
    padding-left: 168px
    padding-right: 168px
    padding-bottom: 68px

.badgeWrapper
    margin-left: 24px

.header
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 100%
    z-index: 9100
    position: -webkit-sticky
    position: sticky
    top: 0
    padding-top: 24px
    padding-bottom: 8px !important
    background-color: #fff
    opacity: 1

    &.headerCentered
        justify-content: center

.headerBf
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: baseline
    padding-bottom: 20px !important
    width: 100%
    z-index: 9100
    position: -webkit-sticky
    position: sticky
    top: 0
    padding-top: 32px
    background-color: #000000
    opacity: 1

    &.headerCentered
        justify-content: center

.headerSpecial
    display: flex
    flex-direction: row
    justify-content: space-between
    padding-bottom: 20px !important
    width: 100%
    padding-top: 32px

.headerActions
    padding: 0
    border: none
    cursor: pointer

.headerWrapActions
    display: flex
    flex-direction: row
    align-items: center
    min-width: 28px
    min-height: 28px

.headerWrapActionsText
    display: flex
    align-items: center

.backBtn
    cursor: pointer
    border: 0
    background: transparent
    margin-right: 8px

.footer
    margin-top: 32px
    display: flex
    width: 100%

.isOpen
    display: flex

// Размеры
.xsm
    width: 574px
    padding: 32px

    .header
        padding-top: 0
        padding-bottom: 0 !important

.xmd
    width: 784px
    padding: 32px

    .header
        padding: 0

.sm
    width: 600px
.lg
    width: 1100px
    padding: 0 16px 16px 16px

    .header
        padding-top: 8px
        padding-bottom: 8px!important

// Новый класс с высотой
.lg-90vh
    width: 1100px
    height: 90vh
    padding: 0 32px 32px 32px
    overflow-y: auto

.dialog
    width: 400px
    padding: 0 32px 32px 32px
.shareSocial
    width: 542px
    padding: 32px
    .header
        padding-top: 0
        padding-bottom: 0 !important

.default
    padding-left: 168px
    padding-right: 168px

.faqCaute
    width: 784px
    padding: 0px 32px 32px 32px

.excursionDetail
    width: 624px
    padding: 0 32px 24px 32px
    z-index: 9999
    p
        margin: 0


.createReview
    width: 1100px
    padding: 0px 32px 32px 32px

.newYear
    width: 920px
    padding: 0
    border-radius: 16px

.newYearMobile
    height: 80%
    width: 100%

.specialWidth
    width: 990px
    padding: 0px 32px 32px 32px

    .header
        padding-top: 56px
        padding-bottom: 56px!important

@media (max-width: 1150px)
    .lg-90vh
        width: 90%
        height: 90vh
        padding: 0 32px 32px 32px
        overflow-y: auto

@media (max-width: 768px)
    .content
        height: 100%
        margin-bottom: 0 !important
        // padding-top: 16px
        border-radius: 0
        width: 100%

    .specialWidth
        width: 100%

    .footer
        margin-top: 16px

    .fullScreen
        padding-left: 16px
        // padding-top: 16px
        padding-right: 16px
        padding-bottom: 20px

    .headerWrapActionsText
        width: 225px
        display: flex
        align-items: center

@media (max-width: 675px)
    .header
        padding-top: 16px
        padding-bottom: 16px !important
    .overlay
        height: 100%
        background-color: #FFF
    .sm
        width: 100%

    .default
        padding-left: 16px
        padding-right: 16px

    .shareSocial
        .header
            padding-bottom: 16px !important

    .bottomModal
        position: fixed
        bottom: 0
        width: 100%
        border-radius: 8px 8px 0 0
        height: auto
        padding: 16px 16px 40px 16px
    .overlayBottom
        background-color: rgba(97, 103, 112, .3)

@media (max-width: 1450px)
    .default
        padding-left: 100px
        padding-right: 100px

@media (max-width: 1150px)
    .default
        padding-left: 16px
        padding-right: 16px
