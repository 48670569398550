.label
    display: flex
    align-items: center
    cursor: pointer
    margin-bottom: 0

.fake
    position: relative
    border: 1px solid rgba(0, 0, 0, 0.15)
    border-radius: 4px
    margin-right: 16px
    background-color: #fff

.invis
    position: relative
    border-radius: 4px
    margin-right: 16px
    background: #fff

// Размеры чекбокса

.sm
    min-width: 32px
    height: 32px

.md
    min-width: 35px
    height: 35px

//
.markCompact
    position: absolute
    height: 17px
    width: 10px
    left: 50%
    top: 45%
    transform: translate(-50%, -50%) rotate(45deg)

    &:before,
    &:after
        content: ''
        position: absolute
        background-color: #A9A9A9
        border-radius: 1px

    &:before
        right: 0
        width: 4px
        height: 100%

    &:after
        left: 0
        bottom: 0
        width: 100%
        height: 4px

.mark
    display: none
    position: absolute
    height: 17px
    width: 10px
    left: 50%
    top: 45%
    transform: translate(-50%, -50%) rotate(45deg)

    &:before,
    &:after
        content: ''
        position: absolute
        background-color: var(--primary-color)
        border-radius: 1px

    &:before
        right: 0
        width: 4px
        height: 100%

    &:after
        left: 0
        bottom: 0
        width: 100%
        height: 4px


.markBf
    display: none
    position: absolute
    height: 17px
    width: 10px
    left: 50%
    top: 45%
    transform: translate(-50%, -50%) rotate(45deg)

    &:before,
    &:after
        content: ''
        position: absolute
        background-color: #FFB500 !important
        border-radius: 1px

    &:before
        right: 0
        width: 4px
        height: 100%

    &:after
        left: 0
        bottom: 0
        width: 100%
        height: 4px

.text
    font-size: 14px
    line-height: 16px

.real:checked ~ .fake .markBf
    display: block

.real:checked ~ .fake .mark
    display: block

.real:checked ~ .markCompact
    background-color: var(--primary-color)

.real:disabled ~ .fake
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF

    .markBf:before,
    .markBf:after
        background-color: transparent

    .mark:before,
    .mark:after
        background-color: transparent

    .markCompact:before,
    .markCompact:after
        background-color: transparent

.real:checked ~ .invis .markCompact
    display: block

    &:after,
    &:before
        background-color: var(--primary-color)

.real:disabled ~ .invis
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF

    .markBf:before,
    .markBf:after
        background-color: #818894

    .mark:before,
    .mark:after
        background-color: #818894

    .markCompact:before,
    .markCompact:after
        background-color: #818894

.loading
    display: flex
    margin-right: 16px
    animation: spin 1s infinite linear

@keyframes spin
    100%
        transform: rotate(360deg)

// Тип текста

.normal
    font-weight: 600

.normalBf
    font-weight: 600
    color: #ffffff !important

    &:hover
        color: #FFB500 !important


.bold
    font-weight: 700
    font-size: 16px

.boldBf
    font-weight: 700
    font-size: 16px
    color: #FFB500 !important

//

.landing
    .invis
        border: 1px solid #7F858C

    .fake
        border: 1px solid #7F858C

    .mark:before,
    .mark:after
        background: #EB776C

    .markCompact:before,
    .markCompact:after
        background: #EB776C

// admin view --------

.real:checked ~ .admin
    background: var(--primary-color)
    border-color: var(--primary-color)

    .mark
        top: 42%
        height: 8px
        width: 5px

        &:before, &:after
            background: #fff

        &:before
            width: 1.5px

        &:after
            height: 1.5px

.admin
    width: 16px
    height: 16px
    min-width: auto
    margin-right: 8px

// -----------------------
