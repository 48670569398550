@font-face
    font-family: 'Onest'
    src: url('../public/fonts/Onest-Regular.woff2')
    src: url('../public/fonts/Onest-Regular.woff2') format('woff2')
    font-style: normal
    font-weight: normal
    font-display: optional

@font-face
    font-family: 'Onest'
    src: url('../public/fonts/Onest-Medium.woff2')
    src: url('../public/fonts/Onest-Medium.woff2') format('woff2')
    font-style: normal
    font-weight: 500
    font-display: optional

@font-face
    font-family: 'Onest'
    src: url('../public/fonts/Onest-SemiBold.woff2')
    src: url('../public/fonts/Onest-SemiBold.woff2') format('woff2')
    font-style: normal
    font-weight: 600
    font-display: optional

@font-face
    font-family: 'Onest'
    src: url('../public/fonts/Onest-Bold.woff2')
    src: url('../public/fonts/Onest-Bold.woff2') format('woff2')
    font-weight: bold
    font-style: normal
    font-display: optional

@font-face
    font-family: 'Inter'
    src: url('../public/fonts/Inter-Regular.woff2')
    src: url('../public/fonts/Inter-Regular.woff2') format('woff2')
    font-style: normal
    font-weight: normal
    font-display: optional
    font-feature-settings: "onum"

@font-face
    font-family: 'Inter'
    src: url('../public/fonts/Inter-Medium.woff2')
    src: url('../public/fonts/Inter-Medium.woff2') format('woff2')
    font-style: normal
    font-weight: 500
    font-display: optional
    font-feature-settings: "onum"


@font-face
    font-family: 'Inter'
    src: url('../public/fonts/Inter-SemiBold.woff2')
    src: url('../public/fonts/Inter-SemiBold.woff2') format('woff2')
    font-style: normal
    font-weight: 600
    font-display: optional
    font-feature-settings: "onum"


@font-face
    font-family: 'Inter'
    src: url('../public/fonts/Inter-Bold.woff2')
    src: url('../public/fonts/Inter-Bold.woff2') format('woff2')
    font-style: normal
    font-weight: bold
    font-display: optional
    font-feature-settings: "onum"

*,
:after,
:before
    box-sizing: border-box
    outline: none

text
    font-family: 'Inter', sans-serif
    font-style: normal
    font-weight: normal

::after,
::before
    text-decoration: inherit
    vertical-align: inherit

html
    cursor: default
    line-height: 1.5
    -moz-tab-size: 4
    -o-tab-size: 4
    tab-size: 4
    -webkit-tap-highlight-color: transparent
    -ms-text-size-adjust: 100%
    -webkit-text-size-adjust: 100%
    word-break: break-word
    scroll-behavior: smooth

body
    margin: 0

h1
    font-size: 2em
    margin: 0.67em 0

dl dl,
dl ol,
dl ul,
ol dl,
ol ol,
ol ul,
ul dl,
ul ol,
ul ul
    margin: 0

hr
    color: inherit
    height: 0
    overflow: visible

main
    display: block

nav ol,
nav ul
    list-style: none
    padding: 0

pre
    font-family: monospace, monospace
    font-size: 1em
    overflow: auto
    -ms-overflow-style: scrollbar

a
    background-color: transparent

abbr[title]
    text-decoration: underline
    -webkit-text-decoration: underline dotted
    text-decoration: underline dotted

b,
strong
    font-weight: bolder

code,
kbd,
samp
    font-family: monospace, monospace
    font-size: 1em

small
    font-size: 80%

audio,
canvas,
iframe,
img,
svg,
video
    vertical-align: middle

audio,
video
    display: inline-block

audio:not([controls])
    display: none
    height: 0

iframe,
img
    border-style: none

svg
    display: block

svg:not([fill])
    fill: currentColor

svg:not(:root)
    overflow: hidden

table
    border-collapse: collapse
    border-color: inherit
    text-indent: 0

button,
input,
select
    margin: 0

button
    overflow: visible
    text-transform: none

[type='button'],
[type='reset'],
[type='submit'],
button
    -webkit-appearance: button

fieldset
    border: 1px solid #a0a0a0
    padding: 0.35em 0.75em 0.625em

input
    overflow: visible

legend
    color: inherit
    display: table
    max-width: 100%
    white-space: normal

progress
    display: inline-block
    vertical-align: baseline

select
    text-transform: none

textarea
    margin: 0
    overflow: auto

[type='checkbox'],
[type='radio']
    padding: 0

[type='search']
    -webkit-appearance: textfield
    outline-offset: -2px

::-webkit-inner-spin-button,
::-webkit-outer-spin-button
    height: auto

::-webkit-input-placeholder
    color: inherit
    opacity: 0.54

::-webkit-search-decoration
    -webkit-appearance: none

::-webkit-file-upload-button
    -webkit-appearance: button
    font: inherit

::-moz-focus-inner
    border-style: none
    padding: 0

:-moz-focusring
    outline: 1px dotted ButtonText

:-moz-ui-invalid
    box-shadow: none

details,
dialog
    display: block

dialog
    background-color: #fff
    border: solid
    color: #000
    height: -moz-fit-content
    height: -webkit-fit-content
    height: fit-content
    left: 0
    margin: auto
    padding: 1em
    position: absolute
    right: 0
    width: -moz-fit-content
    width: -webkit-fit-content
    width: fit-content

dialog:not([open])
    display: none

summary
    display: list-item

canvas
    display: inline-block

template
    display: none

[tabindex],
a,
area,
button,
input,
label,
select,
summary,
textarea
    -ms-touch-action: manipulation
    color: var(--dark-color)

[hidden]
    display: none

[aria-busy='true']
    cursor: progress

[aria-controls]
    cursor: pointer

[aria-disabled='true'],
[disabled]
    cursor: not-allowed

[aria-hidden='false'][hidden]
    display: initial

[aria-hidden='false'][hidden]:not(:focus)
    clip: rect(0, 0, 0, 0)
    position: absolute

[type='color'],
[type='range']
    border-width: 0
    padding: 0

::-ms-expand
    display: none

:-ms-input-placeholder
    color: rgba(0, 0, 0, 0.54)

button,
input,
select,
textarea
    background-color: transparent
    border: 1px solid WindowFrame
    color: inherit
    font: inherit
    letter-spacing: inherit
    padding: 0.25em 0.375em

select
    -moz-appearance: none
    -webkit-appearance: none
    background: no-repeat 100%/1em
    border-radius: 0
    padding-right: 1em

select:not([multiple]):not([size])
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E")
    background-repeat: no-repeat
    background-position: right

[type='color' i],
[type='range' i]
    border-width: 0
    padding: 0

html
    font-family: 'Open Sans', system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji
    color: var(--dark-color)

code,
kbd,
pre,
samp
    font-family: 'Open Sans', Menlo, Consolas, Roboto Mono, 'Ubuntu Monospace', Noto Mono, Oxygen Mono, Liberation Mono, monospace, Apple Color Emoji,Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji

img,
input,
select,
textarea
    height: auto
    max-width: 100%

iframe
    max-width: 100%
    height: 100%

html,
body,
#__next
    height: 100%
    display: flex
    flex-direction: column
    font-size: 14px

body
    display: flex
    flex-direction: column


body, a
    font-family: 'Inter', sans-serif


h1, h2, h3, h4, h5, h6 
    font-family: 'Onest', sans-serif


.visually-hidden
    position: fixed
    clip: rect(0 0 0 0)
    width: 1px
    height: 1px
    margin: -1px

.body-scroll-lock
    overflow: hidden !important

.CautaClick
    cursor: pointer

:root
    --head-text: 32px
    --vlg-text: 18px
    --slg-text: 20px
    --vslg-text: 24px
    --mlg-text: 18px
    --lg-text: 16px
    --md-text: 14px
    --sm-text: 12px
    --vsm-text: 10px
    --background-color: #616770
    --dark-color: #031228
    --secondary: #3E4752
    --input-border-color: rgba(0, 0, 0, 0.15)
    --darken-color: #616770
    --primary-color: #007FFD
    --accent-color: #f3f6f9
    --button-color: #f7f9fc
    --border-color: rgba(3, 18, 40, 0.15)
    --shadow-primary-color: #f3f6f9
    --shadow-opacty-color: #b3c8de
    --red-color: rgba(246, 102, 110, 1)
    --error-color: #e82a1e
    --red2-color: #FD2249
    --darken-color: rgba(3, 18, 40, 0.75)
    --disabled-color: rgba(3, 18, 40, 0.5)
    --lightbackground-color: rgba(24, 70, 143, 0.1)
    --green-color: #80f5c9
    --success-color: #42DB7F
    --purpule-color: #A230D8
    --benefit-color: #42DB7F


.schemePartlyOccupiedCabins
    fill: #FAC657
.schemeVacantCabins
    fill: #A4D361
.schemeSingleCabinsColor
    fill: #4CB7D4

.schemeCabinsSuite
    fill: #937BE4
.schemeCabinsBalcony
    fill: #F9CC09
.schemeCabinsWindow
    fill: #65E5DE
.schemeCabinsObstructedView
    fill: #00B45D
.schemeCabinsPorthole
    fill: #F6666E
.schemeCabinsWithoutWindow
    fill: #4BBBFA

.carousel__inner-slide,
.carousel__slide
    height: 192px !important
    padding: 0 !important

@media (max-width: 991px)
.carousel__inner-slide,
.carousel__slide
    height: 300px !important
    padding: 0 !important

@media (max-width: 768px)
.carousel__inner-slide,
.carousel__slide
    height: 192px !important
    padding: 0 !important

.ReactModal__Overlay
    background-color: rgba(0,0,0,0.4) !important

.ReactModal__Overlay--after-open
    background-color: rgba(0,0,0,0.4) !important

.ReactModal__Content
    padding: 32px !important
    border: none !important
    border-radius: 8px !important

.container--block
    height: 100%
    padding-right: 15px
    padding-left: 15px
    margin-right: auto
    margin-left: auto

.container--between
    height: 100%
    padding-right: 15px
    display: flex
    justify-content: space-between
    padding-left: 15px
    margin-right: auto
    margin-left: auto

.wrapper
    display: flex
    align-items: center
    width: 100%
    height: 100%
    margin-right: auto
    margin-left: auto

.wrapperVosclick
    display: flex
    align-items: center
    gap: 16px
    width: 100%
    height: 100%
    margin-right: auto
    margin-left: auto

    @media (max-width: 767px)
        flex-direction: column

.container
    display: flex
    align-items: center
    width: 100%
    height: 100%
    padding-right: 15px
    padding-left: 15px
    margin-right: auto
    margin-left: auto

    &--end
        justify-content: flex-end

    &--start
        align-items: start
        flex-direction: column

    &--between
        justify-content: space-between

.display-none
    display: none

.Accordion
    border-top-right-radius: 5px
    border-top-left-radius: 5px
    position: relative
    margin-bottom: 8px
    font-size: 16px
    line-height: 24px
    color: #000000
    overflow: hidden

    &__header
        background: var(--accent-color)
        display: flex
        align-items: center
        padding: 16px
        width: 100%
        font-size: 16px
        line-height: 24px
        color: #000000
        cursor: pointer
        font-weight: bold
        border: none
        height: 64px

    &__header:after
        content: ''
        width: 15px
        height: 15px
        display: inline-block
        position: absolute
        right: 15px
        background-size: 12px 12px
        background-repeat: no-repeat
        background-image: url('/icons/close.svg')
        transform: rotate(90deg)
        transition: transform 0.3s

    &__header--collapsed:after
        transform: rotate(45deg)

    &__body
        height: auto
        opacity: 1
        overflow-y: hidden
        background-color: var(--accent-color) !important
        overflow-x: auto
        transition: height 0.2s
        padding: 16px

    &__body--collapsed
        height: 0
        border: 1px solid red
        background: var(--accent-color) !important
        opacity: 0
        padding: 0 70px
        transition: height 0.2s

.promoCode
    background: #ffffff
    padding: 8px
    margin-bottom: 0 !important
    margin-top: 12px
    border-radius: 4px
    border: 2px solid rgba(255, 255, 255, 1)
    border-image: linear-gradient(to right top,rgba(242, 189, 0, 0.5), rgba(230, 59, 5, 0.5))
    -moz-border-image: -moz-linear-gradient(to right top,rgba(242, 189, 0, 0.5), rgba(230, 59, 5, 0.5))
    -webkit-border-image: -webkit-linear-gradient(to right top,rgba(242, 189, 0, 0.5), rgba(230, 59, 5, 0.5))
    border-image-slice: 1
// rgba(242, 189, 0, 0,5)

.promoCode .Accordion__header
    padding-bottom: 12px
    height: 20px !important
    padding-left: 0
    padding-right: 0 !important
    background-color: #fff

.promoCode .Accordion__body
    background-color: #fff !important
    padding: 0 !important
    padding-top: 0 !important

@media (min-width: 576px)
    .container, .container--block
        max-width: 540px

@media (min-width: 768px)
    .container, .container--block
        max-width: 720px

@media (min-width: 992px)
    .container, .container--block
        max-width: 960px

@media (min-width: 1200px)
    .container, .container--block
        max-width: 1100px

@media (min-width: 1400px)
    .container, .container--block
        max-width: 1100px

// Стили для коллтрекинга

.header-phone *
    font-family: 'Inter', sans-serif
    font-style: normal
    font-weight: 700
    font-size: 13px
    line-height: 16px
    text-decoration: none
    color: #68717E

.header-phone-big a
    font-family: 'Inter', sans-serif
    font-style: normal
    font-weight: bold
    font-size: 24px
    line-height: 32px
    text-decoration: none

.landing
    font-family: Manrope, sans-serif
    padding-top: 81px

.landing-container
    max-width: 1048px
    width: 100%
    padding: 0 16px
    margin: 0 auto

@media (max-width: 720px)
    .landing
        padding-top: 56px

@media (max-width: 720px)
    .landing-container
        padding: 0 8px
