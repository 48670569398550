.wrapper
    display: inline-flex
    padding: 8px
    background: red
    display: flex
    background: #f3f6f9
    border-radius: 4px
    overflow: hidden

.link
    padding: 8px 24px
    text-align: center
    color: #0071E1
    background: #F3F6F9
    font-weight: 700
    font-size: 16px
    line-height: 24px
    border-radius: 4px
    cursor: pointer
    border: 0
    margin: 0
    border: 1px solid rgba(0, 0, 0, .15)

    &:hover
        text-decoration: none

    &River
        border-radius: 4px 0 0 4px

    &Sea
        border-radius: 0 4px 4px 0

    &Active
        background: #0071E1
        color: #fff

.wrapperBf
    display: inline-flex
    padding: 8px
    display: flex
    border-radius: 4px
    overflow: hidden

.linkBf
    padding: 8px 24px
    text-align: center
    color: #000000 !important
    background: #141517 !important
    font-weight: 700
    font-size: 16px
    line-height: 24px
    border-radius: 4px
    cursor: pointer
    border: 0
    margin: 0
    border: 1px solid #323C47!important

    &:hover
        text-decoration: none

    &River
        border-radius: 4px 0 0 4px
        color: #FFB500 !important

    &Sea
        border-radius: 0 4px 4px 0
        color: #FFB500 !important

    &ActiveBf
        background: #FFB500 !important
        color: #323C47!important

@media (max-width: 700px)
    .link
        padding: 0
        min-height: 48px
        min-width: 50%
        display: flex
        justify-content: center
        align-items: center

    .wrapper
        min-width: 104%

    .wrapperBf
        min-width: 100%
