.wrapper
    display: inline-flex
    padding: 8px
    background: #F3F6F9

    &WithoutBG
        background: transparent
    &.mobile
        width: 100%

        .list
            width: 100%
            overflow: visible

.list
    display: flex
    background: #FFFFFF
    border-radius: 4px
    overflow: hidden
    border: 1px solid rgba(0, 0, 0, 0.15)

.item
    padding: 8px 25px
    text-align: center
    color: #0071E1
    font-weight: bold
    font-size: 16px
    line-height: 24px
    cursor: pointer
    border: 0

    &:not(:last-child)
        border-right: 1px solid rgba(0, 0, 0, 0.15)

    &:disabled
        cursor: default

    &.mobile
        position: relative
        width: 100%
        text-align: left
        padding-left: 16px
        padding-right: 40px
        border-right: 0
        color: #FFFFFF

        &:after
            content: ''
            position: absolute
            right: 17px
            top: 58%
            transform: translateY(-50%)
            border: 6px solid transparent
            border-top: 6px solid #0071E1

.dropdown
    position: relative
    width: 100%

    &Mob
        position: relative
        width: 100%
        background-color: #0071E1
        border-radius: 4px


.booking
    display: flex
    width: 100%
    justify-content: space-between

    &Button
        //border: 2px solid
        border-radius: 4px
        margin-bottom: 16px
        width: 49%
        border: 1px solid rgba(0, 0, 0, 0.10)
        &:hover
            border: 1px solid rgba(0, 0, 0, 0.50)

    &Item
        padding: 20px 25px
        text-align: center
        font-weight: bold
        font-size: 16px
        line-height: 24px
        cursor: pointer
        border: 0
        width: 100%

        &:not(:last-child)
            border-right: 1px solid rgba(0, 0, 0, 0.50)

        &:disabled
            cursor: default

        &.mobile
            position: relative
            width: 100%
            text-align: left
            padding-left: 16px
            padding-right: 40px
            border-right: 0

            &:after
                content: ''
                position: absolute
                right: 17px
                top: 58%
                transform: translateY(-50%)
                border: 6px solid transparent
                border-top: 6px solid #0071E1
    &Active
        border: 1px solid rgba(0, 0, 0, 0.50)

.body
    position: absolute
    z-index: 25
    top: calc(100% + 8px)
    left: -8px
    background: #fff
    width: calc(100% + 16px)
    border: 1px solid rgba(0, 0, 0, 0.15)
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1)
    border-radius: 4px
    padding: 16px

    button
        display: block
        width: 100%
        text-align: left
        font-weight: bold
        font-size: 16px
        line-height: 24px
        color: #0071E1
        border: 0
        cursor: pointer

        &:not(:first-child)
            margin-top: 16px

.active
    background: #0071E1
    color: #fff
