.checkboxes
    overflow-y: scroll
    height: 240px

    &::-webkit-scrollbar
        background-color: #fff
        width: 16px

    &::-webkit-scrollbar-track
        background-color: #fff

    &::-webkit-scrollbar-thumb
        background-color: #babac0
        border-radius: 16px
        border: 4px solid #fff

    &::-webkit-scrollbar-button
        display: none

    &Inside
        display: flex
        align-items: center
        gap: 5px

        &SubText
            color: #898C92
            font-size: 14px
            font-weight: 400
            line-height: 16px

.checkboxes > *:not(:last-child)
    margin-bottom: 16px
