.wrapper
    background-color: #F3F6F9
    border-radius: 24px
    width: 100%
    

.block
    display: flex
    flex-direction: column
    gap: 16px

.blockForm
    display: flex
    gap: 16px

.form
    display: flex
    padding: 32px 0 0 32px
    justify-content: space-between

.wrapperHeader
    font-size: 20px
    font-weight: 700
    line-height: 24px
    color: #031228

.wrapperDescription
    font-size: 16px
    font-weight: 400
    line-height: 24px
    color: #031228


.submitButton
    // width: 100%
    margin-bottom: 16px
    background-color: #007FFD 
    color: #fff 

.agreement 
    color: rgba(3, 18, 40, 0.50)
    font-size: 12px
    font-weight: 400
    line-height: 28px 

.politic
    color: rgba(3, 18, 40, 0.50)
    font-size: 14px 
    font-weight: 400
    line-height: 20px

.image
    border-radius: 24px 0

.successForm
    display: flex
    align-items: center
    height: 100%
    justify-content: center

.success
    height: 300px
    border-radius: 16px
    display: flex
    text-align: center
    flex-direction: column
    justify-content: center

    &Content
        display: flex
        flex-direction: column
        align-items: center

.icon
    width: 120px
    height: 120px
    display: flex
    align-items: center
    text-align: center
    justify-content: center
    background-color: #9ACBF9
    border-radius: 50%

.text
    color: #031228
    font-size: 16px 
    font-weight: 400
    line-height: 24px
    margin: 8px auto 0

@media (max-width: 500px)

    .wrapper
        border-radius: 0

    .form
        padding: 32px 16px

    .blockForm    
        flex-direction: column

    .wrapperDescription
        font-size: 12px
        font-weight: 400
        line-height: 18px
        color: #031228

    .agreement 
        font-size: 12px
        line-height: 18px 

    .politic
        font-size: 12px 
        line-height: 18px

    .successForm
        justify-content: center
        height: 472px
