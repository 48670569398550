.search
    display: grid
    grid-template-columns: repeat(4, 4fr)
    grid-template-rows: repeat(2, 64px)
    grid-gap: 8px
    background: #F3F6F9
    font-size: 16px
    line-height: 24px
    color: #031228
    padding: 8px
    border-radius: 8px
    position: relative

    &ToResult
        width: 100%
        height: 100%
        background: var(--primary-color)
        color: #fff
        display: flex
        align-items: center
        justify-content: center
        font-weight: bold
        font-size: var(--mlg-text)
        line-height: 24px
        text-decoration: none
        border-radius: 4px

        &Disabled
            pointer-events: none
            cursor: not-allowed
            background: rgba(105, 110, 118, 0.5)
            padding-left: 9px

    & > * > *
        height: 100%

    &NewYear
        width: 100%
        height: 100%
        background: #17C613
        color: #fff
        display: flex
        align-items: center
        justify-content: center
        font-weight: bold
        font-size: var(--mlg-text)
        line-height: 24px
        text-decoration: none
        border-radius: 4px

.searchBf
    display: grid
    grid-template-columns: repeat(4, 4fr)
    grid-template-rows: repeat(2, 64px)
    grid-gap: 8px
    background: #000000
    font-size: 16px
    line-height: 24px
    color: #031228

    &ToResult
        width: 100%
        height: 100%
        background: var(--primary-color)
        color: #fff
        display: flex
        align-items: center
        justify-content: center
        font-weight: bold
        font-size: var(--mlg-text)
        line-height: 24px
        text-decoration: none
        border-radius: 4px

        &Disabled
            pointer-events: none
            cursor: not-allowed
            background: rgba(105, 110, 118, 0.5)
            padding-left: 9px

    & > * > *
        height: 100%

.loading
    display: flex
    animation: spin 1s infinite linear

@keyframes spin
    100%
        transform: rotate(360deg)
.tabs
    display: flex
    justify-content: center
    margin-bottom: 24px

.one
    grid-column: 1 / 3

.two
    grid-column: 3 / 5

.search.landing
    background: transparent
    padding: 0

.six.landing
    display: none

.hide
    display: none

.searchFooter
    display: flex
    align-items: center

    & > *
        padding: 0
        font-size: 16px
        line-height: 24px
        margin-top: 16px

.showLastHistory
    margin-left: auto

.admin
    width: 700px
    grid-template-columns: unset
    grid-template-rows: unset
    grid-gap: unset
    display: flex
    flex-wrap: wrap
    background: transparent
    padding: 0
    gap: 16px 24px

    & > *
        height: 34px

    .one,
    .two
        grid-column: unset

@media (min-width: 1080px)
    .search.landing
        grid-gap: 0
        grid-template-rows: 56px
        grid-template-columns: 194px 230px 180px 195px 220px

        .one,
        .two
            grid-column: auto

@media (max-width: 992px)
    .search
        grid-template-columns: repeat(2, 4fr)
        grid-template-rows: repeat(5, 64px)

    .searchBf
        grid-template-columns: repeat(2, 4fr)
        grid-template-rows: repeat(5, 64px)

    .two,
    .five,
    .six
        grid-column: 1 / 3

    .searchFooter
        flex-direction: column
        align-items: flex-start

        & > *
            margin-top: 24px

    .showLastHistory
        margin-left: 0

.landingSearch
    display: inline-flex
    align-items: center
    border: 0
    margin: 18px auto 0
    background-color: #549AEB
    color: #fff
    padding: 15px 28px
    border-radius: 8px
    text-decoration: none

    &.inGrid
        display: flex
        justify-content: center
        padding: 0
        margin: 0
        border-radius: 4px

    img
        margin-right: 11px

    span
        font-family: Manrope, sans-serif
        font-size: 18px
        line-height: 145%

.landingFooter
    display: flex
    justify-content: center

@media (max-width: 1080px)
    .landingFooter
        display: none

    .six.landing
        display: block

        button
            display: flex
            align-items: center
            justify-content: center
            border: 0
            background-color: #EB776C
            color: #fff
            padding: 15px 28px
            width: 100%
            border-radius: 4px

            img
                margin-right: 11px

            span
                font-family: Manrope, sans-serif
                font-size: 18px
                line-height: 145%

@media (max-width: 400px)
    .search:not(.landing)
        margin: 0 -8px
        padding: 0 15px
