.wrapper
    display: flex
    align-items: center
    justify-content: space-between

.text
    width: 144px
    font-weight: 600
    font-size: 14px
    line-height: 16px

.main
    display: flex
    align-items: center

.num
    display: flex
    margin: 0 15px
    font-weight: bold
    font-size: 20px
    line-height: 24px

.btn
    position: relative
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    background: #F3F6F9
    border-radius: 4px
    width: 32px
    height: 32px
    font-size: 0
    border: 0

    &:before,
    &:after
        content: ''
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        background-color: var(--primary-color)

    &:before
        width: 16px
        height: 4px

    &:after
        display: none
        width: 4px
        height: 16px

    &.plus:after
        display: block

    &:disabled:before,
    &:disabled:after
        background-color: #818894 !important


.btnBf
    position: relative
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    background: #F3F6F9
    border-radius: 4px
    width: 32px
    height: 32px
    font-size: 0
    border: 0

    &:before,
    &:after
        content: ''
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        background-color: #FFB500

    &:before
        width: 16px
        height: 4px

    &:after
        display: none
        width: 4px
        height: 16px

    &.plus:after
        display: block

    &:disabled:before,
    &:disabled:after
        background-color: #818894 !important


.landing
    .btn
        background: #FBE5E3
        border: 0

        &:before,
        &:after
            background: #EB776C
