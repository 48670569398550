//TODO: Хуита
.heading-1
    font-weight: 800 !important
    font-size: var(--head-text) !important
    line-height: 40px !important
    margin: 0

.newPromo
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: 20px
    color: #007FFD

.heading-lg
    margin: 0
    font-weight: 800 !important
    font-size: 48px !important
    line-height: 56px !important
    font-feature-settings: 'liga' off

.header-admin-lg
    font-family: 'Roboto', sans-serif !important
    font-weight: 400 !important
    font-size: 32px !important
    line-height: 130% !important
    letter-spacing: 0.1px !important
    color: #000000 !important
    margin: 0

// .blackFriday
//     background-color: #3C3C54
//     border-radius: 8px
//     font-weight: 800 !important
//     font-size: var(--head-text) !important
//     line-height: 24px !important
//     max-height: 50px
//     display: flex
//     align-items: center
//     max-width: 308px
//     justify-content: center
//     margin-top: 40px
//     margin-bottom: 52px
//     padding-bottom: 0

@media (max-width: 769px)
    .heading-lg
        font-size: 36px !important
        line-height: 40px !important

.heading-2
    font-weight: bold !important
    font-size: 24px !important
    line-height: 24px !important
    margin: 0

.heading-3
    font-weight: bold
    font-size: 20px !important
    line-height: 24px
    margin: 0

.heading-4
    font-weight: bold !important
    font-size: 24px !important
    line-height: 32px !important

.heading-5
    font-weight: bold !important
    font-size: 32px !important
    line-height: 40px !important

.heading-6
    font-weight: bold !important
    font-size: var(--mlg-text) !important
    line-height: 20px !important

.heading-7
    font-weight: bold !important
    font-size: var(--lg-text) !important
    line-height: 24px !important

.heading-8
    font-weight: bold !important
    font-size: var(--mlg-text) !important
    line-height: 24px !important

.heading-9
    font-weight: bold !important
    font-size: var(--slg-text) !important
    line-height: 24px !important

.heading-10
    font-weight: bold !important
    font-size: var(--vslg-text) !important
    line-height: 32px !important

.heading-11
    font-style: normal !important
    font-weight: 800 !important
    font-size: 28px !important
    line-height: 32px !important

.heading-12
    font-style: normal !important
    font-weight: 700 !important
    font-size: 20px !important
    line-height: 28px !important

.heading-13
    font-weight: bold !important
    font-size: 12px !important
    color: var(--dark-color) !important
    line-height: 13px !important

.secondary
    font-size: 13px !important
    font-weight: 400
    line-height: 12px !important
    color: var(--secondary) !important
    text-align: left !important

    .secondary
    font-size: 13px !important
    font-weight: 400
    line-height: 12px !important
    color: var(--secondary) !important
    text-align: left !important

.email
    font-weight: 700
    font-size: 24px !important
    line-height: 32px !important
    color: #007FFD

.textForBanner
    font-weight: bold !important
    font-size: var(--slg-text) !important
    line-height: 24px !important

.text
    font-size: var(--md-text)
    text-decoration: none
    line-height: 20px

.text-1
    font-style: normal
    font-weight: normal
    font-size: var(--md-text)
    line-height: 20px
    color: var(--darken-color)

.text-2
    font-weight: 700 !important
    font-size: var(--vsm-text) !important
    line-height: 16px !important

.text-3
    font-weight: bold
    font-size: var(--sm-text)
    line-height: 16px

.text-4
    font-weight: bold
    font-size: var(--md-text)
    line-height: 20px

.text-5
    font-weight: bold
    font-size: var(--md-text)
    line-height: 24px

.text-6
    font-style: normal
    font-weight: normal
    font-size: var(--md-text)
    line-height: 20px
    font-size: 16px !important
    line-height: 24px !important
    font-weight: bold
    color: var(--primary-color) !important

.activePage
    color: #007ffb

.text-7
    font-weight: bold
    font-size: var(--md-text)
    line-height: 16px

.text-8
    font-style: normal
    font-weight: bold
    font-size: var(--md-text)
    line-height: 20px
    color: var(--darken-color)

.subText
    font-size: 16px !important
    line-height: 24px
    color: #031228
    font-weight: 400

.litresButton
    padding: 12px 20px 12px 20px
    background-color: #007FFD
    color: #fff
    border-radius: 4px

.presentButton
    color: #FFF
    font-size: 18px !important
    font-style: normal
    font-weight: 700
    line-height: 24px

.present
    color: #F07115
    font-size: 16px !important
    font-style: normal
    font-weight: 700
    line-height: 24px

.text-9
    font-style: normal
    font-weight: bold
    font-size: var(--sm-text)
    line-height: 16px
    color: var(--darken-color)
.text-10
    font-style: normal
    font-weight: 400
    font-size: var(--lg-text) !important
    line-height: 24px

.text-11
    font-style: normal
    font-weight: 400 !important
    font-size: var(--sm-text) !important
    line-height: var(--lg-text) !important

.thanksPageIntro
    font-style: normal
    line-height: 56px
    font-weight: 800
    font-size: 48px !important
    color: var(--primary-color)
    font-feature-settings: 'liga' off

.thanksPageIntroMob
    // font-style: normal
    line-height: 32px !important
    font-weight: 800
    font-size: 32px !important
    color: var(--primary-color) !important

.additionalConditions
    font-weight: 500 !important
    font-size: 12px !important
    line-height: 14px !important
    color: #fff !important

.thanksPageIntroMobile
    font-style: normal!important
    line-height: 40px !important
    font-weight: 800 !important
    font-size: 36px !important
    color: var(--primary-color) !important
    font-feature-settings: 'liga' off !important

.next
    font-size: var(--head-text) !important
    font-style: normal
    font-weight: 800
    line-height: 40px
    color: var(--dark-color)

.nextMobile
    font-size: 28px !important
    font-style: normal
    font-weight: 800
    line-height: 32px !important
    color: var(--dark-color)

.point
    font-size: 20px !important
    font-style: normal !important
    font-weight: 700 !important
    line-height: 24px !important
    color: var(--dark-color) !important

.toDoText
    font-size: 20px !important
    font-style: normal !important
    font-weight: 400 !important
    line-height: 24px !important
    color: var(--dark-color) !important

.toDoTextMob
    font-size: 16px !important
    font-style: normal !important
    font-weight: 400 !important
    line-height: 24px !important
    color: var(--dark-color) !important

.primaryLinkAsButton
    background: #4A86FF !important
    min-width: 100% !important
    font-size: var(--lg-text) !important
    line-height: 24px !important
    width: 100%
    color: #FFFFFF !important
    padding: 16px !important
    border-radius: 4px !important

.textAmount
    font-weight: bold
    font-size: 18px
    line-height: 20px
    display: flex
    align-items: end

.litresPresent
    color: #031228
    font-size: 32px !important
    font-style: normal !important
    font-weight: 800 !important
    line-height: 40px !important

.uCity
    font-weight: bold
    font-size: 28px
    // line-height: 32px
    padding-bottom: 8px

.uCityCent
    font-size: 28px
    // line-height: 32px
    padding-bottom: 8px

.popularName
    font-weight: bold
    font-size: 24px
    line-height: 24px

.ToolTipCent
    font-weight: bold
    font-size: 16px
    line-height: 24px
    color: #FFFFFF

.ToolTipNumber
    font-size: 16px
    line-height: 24px
    color: #FFFFFF

.footersea
    font-weight: bold !important
    font-size: 14px !important
    line-height: 24px !important
    color: var(--primary-color) !important
    
.benefittextbold
    font-weight: bold !important
    font-size: 16px !important
    line-height: 24px !important
    display: flex
    justify-content: center
    align-items: center

.benefittextboldCashback
    font-weight: bold !important
    font-size: 16px !important
    line-height: 24px !important
    display: flex
    align-items: center

.profitgreen
    font-weight: bold !important
    font-size: 16px !important
    line-height: 24px !important
    display: flex
    justify-content: center
    align-items: center
    color: rgba(48, 216, 145, 0.5) !important

.innogrn
    font-size: 14px
    line-height: 20px
    color: #818894

.vosclic
    font-weight: bold
    font-size: 14px
    line-height: 20px

.benifittitle
    font-size: 16px !important
    line-height: 24px !important
    font-weight: bold !important
    color: var(--dark-color)
    display: flex
    justify-content: center
    align-items: center

.benifittitleBf
    font-size: 16px !important
    line-height: 24px !important
    font-weight: bold !important
    color: #fff !important
    display: flex
    justify-content: center
    align-items: center

.booking
    font-weight: bold !important
    font-size: var(--lg-text) !important
    line-height: 24px !important
    width: 100%
    height: 100%

.linkAsButtonPrimarySm
    font-size: 12px !important
    line-height: 16px !important
    font-weight: bold
    color: var(--primary-color) !important

.linkAsButtonPrimary
    font-weight: bold !important
    font-size: 16px !important
    line-height: 24px !important
    color: var(--primary-color) !important

.reviewHref
    font-weight: bold !important
    font-size: 14px !important
    line-height: 20px !important
    color: var(--primary-color) !important
    cursor: pointer

.linkAsButtonPrimary2
    font-weight: bold !important
    font-size: 16px !important
    line-height: 24px !important
    padding: 15px 24px
    color: #fff
    border-radius: 4px
    display: flex
    align-items: center
    justify-content: center
    background-color: var(--primary-color) !important

.linkAsButtonPrimary4
    font-weight: bold !important
    font-size: var(--mlg-text) !important
    line-height: 28px !important
    padding: 16px
    color: #fff
    border-radius: 4px
    display: flex
    align-items: center
    justify-content: center
    background-color: var(--primary-color) !important

.benefitFtitle
    font-weight: bold !important
    font-size: 14px !important
    line-height: 20px !important
    align-items: center
    color: #031228

.benefitFunder
    font-size: 12px
    line-height: 16px
    color: #031228

.promo
    font-size: 12px
    line-height: 16px
    font-weight: bold
    color: #616770

.seaRerquest
    font-weight: 700
    font-size: 24px !important
    line-height: 32px
    color: #031228

.promocodesCopy
    font-weight: 800 !important
    font-size: 24px !important
    line-height: 28px
    color: #031228

.copyText
    font-weight: 500 !important
    font-size: 24px !important
    line-height: 28px
    color: #031228
   
.promocodes
    font-weight: 700 !important
    font-size: 24px !important
    line-height: 28px
    color: #031228
    
.seaRerquestText
    font-weight: 700
    font-size: 18px
    line-height: 24px !important
    color: rgba(3, 18, 40, 0.5)

.admButton
    min-width: 280px
    min-height: 104px
    background-color: rgba(255, 255, 255, 0.4)
    box-shadow: 10px 10px 20px rgba(74, 134, 255, 0.3)
    border-radius: 72px
    display: flex
    align-items: center
    justify-content: center

.copyright
    font-weight: 400
    font-size: 12px
    line-height: 28px
    color: #626687

.undertext
    font-weight: bold
    font-size: 12px
    line-height: 16px
    display: flex
    justify-content: center
    align-items: center
    color: rgba(3, 18, 40, 0.5)

.undertextCashback
    font-weight: bold
    font-size: 12px
    line-height: 16px
    display: flex
    align-items: center
    color: rgba(3, 18, 40, 0.5)

.linkAsbutton
    color: var(--primary-color)
    background: #FFFFFF
    border-radius: 4px
    padding: 4px 8px

.linkAsbuttonBf
    color: #FFB500 !important
    background: #141517 !important
    border-radius: 4px
    padding: 4px 8px

.linkAsbutton3
    color: var(--primary-color)
    background: #FFFFFF
    border-radius: 4px
    padding: 4px 8px
    font-weight: bold
    font-size: 12px
    line-height: 16px

.linkAsbuttonCashback
    color: var(--primary-color)
    background: #F3F6F9
    border-radius: 4px
    padding: 4px 8px
    font-weight: bold
    font-size: 12px
    line-height: 16px

.linkAsbutton2
    color: var(--primary-color)
    border-radius: 4px
    padding: 8px 12px
    font-weight: bold
    background-color: var(--accent-color)

.linkAsbuttonFull2
    color: var(--primary-color)
    border-radius: 4px
    padding: 16px 8px
    font-weight: bold
    background-color: var(--accent-color)
    width: 100%
    display: flex
    align-items: center
    justify-content: center

.buttonBack
    text-decoration: none

.freePlaces
    font-weight: 700
    font-size: 12px
    line-height: 16px
    color: #FD2249

.showMore
    background-color: #fff
    color: var(--primary-color)
    border: 1px solid var(--primary-color)
    height: 100%
    min-width: 100%
    display: flex
    justify-content: center
    align-items: center
    text-decoration: none

.cruiseCity
    font-weight: 800
    font-size: 32px
    line-height: 40px
    display: flex
    align-items: center

.famyle
    color: #031228
    font-size: 14px
    line-height: 20px

.famyleBolt
    color: #031228
    font-size: 14px
    line-height: 20px
    font-weight: bold

.shipsShowMore
    background-color: #fff
    color: var(--primary-color)
    border: 1px solid var(--primary-color)
    padding-top: 10px
    padding-bottom: 10px
    height: 40px
    width: 100%

.safetyGG
    color: #031228
    font-weight: bold
    font-size: 20px
    line-height: 24px
    margin: 5px 0

.accordionItemsLi
    color: #031228
    font-weight: bold
    padding-top: 3px
    padding-left: 5px

.safety
    color: #031228
    font-size: 14px
    line-height: 15px
    margin: 5px 0

.link
    padding: 6px

    &:hover
        text-decoration: underline

.linkAsbuttonMore
    background: var(--accent-color)
    border-radius: 4px
    padding: 12px 16px
    color: var(--primary-color) !important

.blogNewYear
    background: var(--accent-color)
    border-radius: 4px
    padding: 10px 20px
    color: var(--primary-color) !important


.linkAsbuttonMoreBF
    background: #141517
    border-radius: 4px
    padding: 12px 16px
    color: #FFB500 !important

.sm
    font-size: var(--sm-text)

.md
    font-size: var(--md-text)

.priceLine
    display: flex
    position: relative

    &::before
        position: absolute
        content: ''
        width: 100%
        height: 2px
        background: red
        display: flex
        top: 50%
        border-radius: 4px
        transform: rotate(-5deg)
        background: var(--error-color)

.yandexButton
    width: 100%
    background: #F3F6F9
    border-radius: 4px
    color: var(--primary-color)
    display: flex
    align-items: center
    justify-content: center
    line-height: 20px
    font-weight: bold
    padding-top: 14px
    padding-bottom: 14px

@media ( max-width: 500px )
    .yandexButton
        flex-direction: column

.green
    color: var(--success-color)

.primaryColor
    color: var(--primary-color)
.darkenColor
    color: #616770
.greenColor
    color: #0CAF58
.red
    color: #E9143B
.orange
    color: #F07115
.orangePromo
    color: #E58C23
.darken
    color: var(--darken-color)

.subColor
    color: #818894
.white
    color: #fff !important

.cashback
    color: #00AADE

.discount
    color: var(--success-color)
.green
    color: var(--success-color)
.success
    color: #0CAF58
.benefitGreen
    color: var(--benefit-color)

.error
    color: var(--error-color)

.red2
    color: var(--red2-color)

.dark-color
    color: var(--dark-color)

.returnTrip
    color: #808080

.excursionProgramm
    font-size: 16px
    line-height: 24px

.timeTable
    font-weight: 700
    font-size: 13px
    line-height: 16px
    text-decoration: none
    color: var(--primary-color)

.timeTableBf
    font-weight: 700
    font-size: 13px
    line-height: 16px
    text-decoration: none
    color: #FFB500 !important

.landingSale
    font-size: 24px
    color: #EB776C

.landingSaleButton
    background-color: var(--primary-color)
    color: #fff
    font-size: 16px
    border-radius: 4px
    padding: 12px 16px

.blackFriday
    font-weight: 700
    font-size: 36px
    line-height: 48px
    color: #ffffff

.blackFridayMob
    font-weight: 700 !important
    font-size: 24px !important
    line-height: 27px !important
    color: #ffffff !important

.countDownTime
    font-weight: 700 !important
    font-size: 16px !important
    line-height: 22px !important
    color: #ffffff !important

.countDown
    font-weight: 700
    font-size: 32px
    line-height: 32px
    color: #ffffff

.countDownMob
    font-weight: 800
    font-size: 24px
    line-height: 40px
    color: #ffffff !important

.advantages
    font-weight: 800
    font-size: 64px
    line-height: 64px
    color: #FFB500

.advantagesMob
    font-weight: 800 !important
    font-size: 35px !important
    line-height: 40px !important
    color: #FFB500 !important

.advantagesTextMob
    color: #FFFFFF !important
    font-size: 18px !important
    font-weight: 600 !important
    line-height: 18px !important
    margin-top: 12px

.textBF
    font-style: normal
    font-weight: normal
    font-size: var(--md-text)
    line-height: 20px
    color: #ffffff !important

.countBF
    font-weight: 700
    font-size: 25px
    line-height: 31px
    color: #FFB500 !important

.toDoTextBF
    font-weight: 400
    font-size: 21px !important
    line-height: 28px 
    color: #F4F4F4 !important

.priceTizer
    font-weight: 600
    font-size: 24px !important
    line-height: 24px 
    color: #FFB500!important

.discountText
    color: #F86F6C !important
    font-weight: 700 !important
    font-size: 16px !important
    line-height: 18px !important

.bannerText
    color: #FFFFFF
    font-size: 20px
    font-weight: 800
    line-height: 24px

.bannerTextFirst
    color: #FFFFFF
    font-size: 40px
    font-weight: 900
    line-height: 50px

.bannerTextSecond
    color: #FFFFFF
    font-size: 32px
    font-weight: 600
    line-height: 40px

.secondaryText
    font-weight: 800 !important
    color: #FFFFFF
    font-size: 32px
    line-height: 40px

.secondaryTextMob
    font-weight: 800 !important
    color: #FFFFFF
    font-size: 22px !important
    line-height: 30px


.mainPrize
    color: #FFFFFF
    font-size: 22px
    font-weight: 700
    line-height: 28px

.bannerTextMobile2
    color: #FFFFFF
    font-size: 32px
    font-weight: 600
    line-height: 40px

.heading-13
    font-weight: 800
    font-size: 40px
    line-height: 100%
    color: #FFFFFF

.heading-14
    font-weight: 600
    font-size: 22px
    line-height: 119%
    color: #FFFFFF

.bannerTextMobile
    color: #FFFFFF
    font-size: 14px
    font-weight: 800
    line-height: 24px

.advertisingText
    font-size: 16px
    line-height: 24px
    font-weight: 700

.partnerText
    font-size: 16px
    font-weight: 400
    line-height: 28px
    color: #626687

.partnerText2
    font-size: 40px
    line-height: 50px
    font-weight: 600
    color: #0D224E

.statistics
    font-weight: 700
    font-size: 25px
    line-height: 31px
    color: #0D224E

.text-12
    font-weight: 400
    font-size: 22px
    line-height: 28px
    color: #0D224E

.text-13
    font-size: 16px
    font-weight: 600
    line-height: 28px
    color: #1B1C57

.text-14
    font-size: 16px
    font-weight: 600
    line-height: 28px
    opacity: 0.75
    color: #FFFFFF

.text-15
    font-size: 16px
    font-weight: 400
    line-height: 28px
    opacity: 0.75
    color: #FFFFFF

.text-16
    font-size: var(--slg-text)
    font-weight: 400
    line-height: 28px

.trafficTypes
    font-weight: 700
    font-size: 25px
    line-height: 31px
    color: #EF3959

.programsText
    color: #626687
    font-weight: 400
    font-size: 16px
    line-height: 28px

.partnersPrograms
    font-weight: 600
    font-size: 32px
    line-height: 40px
    color: #1B1C57

.joinUs
    font-weight: 600
    font-size: 32px
    line-height: 40px
    color: #1B1C57
    margin-top: 64px

.buttonCruiz
    background-color: #EF3959
    border-radius: 72px
    padding: 12px 24px
    gap: 10px
    color: #FFFF
    font-weight: 600
    line-height: 22px
    font-size: 14px
    box-shadow: 0 0 40px rgba(239, 57, 89, 0.4)

.circle
    font-weight: 700
    font-size: 18px
    line-height: 22px
    color: #4A86FF

.square
    font-weight: 400
    font-size: 24px
    line-height: 30px
    color: #0D224E

.square-2
    font-weight: 400
    font-size: 18px
    line-height: 26px
    color: #0D224E

.textLeads
    font-weight: 400
    font-size: 24px
    line-height: 30px
    color: #0D224E

.textLeads2
    font-weight: 400
    font-size: 18px
    line-height: 22px
    color: #0D224E

.footerTextLand
    font-weight: 500
    font-size: 16px
    line-height: 20px

.uniqueSales
    font-weight: 500
    font-size: 20px
    line-height: 32px
    color: #3C4563

.seaContacts
    font-weight: 400
    font-size: 16px
    line-height: 24px
    color: #000000

.price
    font-weight: 700
    font-size: 24px
    line-height: 32px
    color: #031228

.missing
    font-weight: 700
    font-size: 12px
    line-height: 16px
    color: #031228

.link-2
    color: var(--shadow-opacty-color)
    font-size: var(--lg-text)
    font-weight: bold

.textShadow-2
    color: var(--shadow-opacty-color)
    font-size: var(--lg-text)
    font-weight: bold
    opacity: 0.5

.textHeadDiscount
    font-weight: bold !important
    font-size: var(--mlg-text) !important
    line-height: 20px !important
    color: rgba(0, 0, 0)
    opacity: 0.5

.textShadow
    font-weight: bold !important
    font-size: 20px !important
    line-height: 24px !important
    margin: 0
    text-shadow: 0 2px rgba(0, 0, 0, 0.25)

.textShadowSmall
    font-weight: bold
    font-size: var(--sm-text)
    line-height: 16px
    text-shadow: 0 1.6px rgba(0, 0, 0, 0.25)

.textDiscountSpecial
    text-transform: uppercase
    color: #FD2249
    font-size: 12px
    font-style: normal
    font-weight: bold

.textDiscountCashback
    text-transform: uppercase
    color: #00AADE
    font-size: 12px
    font-style: normal
    font-weight: bold

.linkButtonBooking
    color: #007FFD
    text-decoration: none
    font-size: 16px
    font-style: normal
    line-height: 24px
    cursor: pointer

.linkTextPopap
    color: #031228
    font-size: 14px
    line-height: 20px
    font-weight: 700

.linkButtonBookingBold
    color: #007FFD
    text-decoration: none
    font-size: 16px
    font-style: normal
    line-height: 24px
    cursor: pointer
    font-weight: bold

.linkDirections
    color: var(--primary-color)
    font-size: 14px
    line-height: 20px
    font-weight: 700
    text-decoration: none
    cursor: pointer

.priceButtonShips
    color: #FFFF
    text-decoration: none
    font-weight: 700
    font-size: 18px
    line-height: 24px
    background-color: #007FFD
    border-radius: 4px
    padding: 12px 20px
    cursor: pointer
    max-width: 100%
    min-width: 304px
    text-align: center
    margin-bottom: 8px

.medicalInfo
    font-size: 12px
    line-height: 16px
    font-weight: 400
