.wrapper
  display: grid
  padding-top: 60px
  margin-top: 40px
  border-radius: 24px 24px 0 0


.innerWrapper
  background-image: url(/newYear/footerBackground.png)
  background-color: #007FFD !important
  border-radius: 24px 24px 0 0
  background-repeat: no-repeat
  background-position: bottom
  background-size: contain
  padding-bottom: 80px
  padding-top: 60px
  margin-top: 40px
  max-width: 100%
  display: grid

.backgroud
  width: 100%

  &Image
    display: flex
    justify-content: space-between
    position: rela

.otherPages
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  width: 100%
  margin-bottom: 50px
  padding-bottom: 20px
  border-bottom: 1px solid #4a8ff7
 

.footerLI
  font-size: 14px
  line-height: 20px
  display: flex
  padding-top: 1px

.text
  font-size: 14px
  font-weight: 400
  line-height: 20px
  color: #FFFFFF !important


.socialWrapper
  display: flex
  margin-bottom: 20px
  justify-content: center
  gap: 300px
  width: 100%


.number
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.info
  width: 100%
  padding-top: 8px
  
.footerSocial
  display: flex
  flex-direction: column
  justify-content: center
  gap: 4px


.social
  display: flex
  align-items: center
  justify-content: space-evenly
  gap: 10px

.blogCompany
  min-width: 59px
  // padding: 6px 12px !important

.paymentMethods
  display: flex
  margin-bottom: 40px
  justify-content: center
  gap: 40px
  width: 100%


.paymentMethodTinkoff
  display: flex
  justify-content: end
  align-items: center

.paymentMethodSbp
  display: flex
  justify-content: start
  align-items: center


.footerCompany
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  padding-bottom: 42px
  width: 100%

@media (max-width: 500px)

  .otherPages
    display: flex
    flex-direction: column

  .socialWrapper
    display: flex
    text-align: center
    flex-direction: column
    gap: 24px

  .blogCompany
    width: 100%
    text-align: center

  .social
    gap: 0


