.hide
    display: none
    visibility: hidden

.loading
    display: flex
    animation: spin 1s infinite linear

@keyframes spin
    100%
        transform: rotate(360deg)

.compact
    display: flex
    flex-direction: column-reverse
    transition: all 0.2s
    touch-action: manipulation
    background-color: #fff
    overflow: hidden
    max-height: 32px
    border-radius: 4px

    &.disabled
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF


        &.fieldReadOnly *
            cursor: default !important

        .fieldArrow:before
            border-top: 8px solid rgba(3, 18, 40, 0.5)

        .fieldLabel
            opacity: 0.5

        .fieldControl
            color: rgba(3, 18, 40, 0.5)

    &Action
        position: absolute
        right: 10px
        top: 50%
        transform: translateY(-50%)
        display: flex

    &ArrowBtn
        padding: 0
        border: 0
        transition: transform .2s

        &.active
            transform: rotate(180deg)

            .compactArrow:before
                border-top: 6px solid #007FFD

            &.landing .compactArrow:before
                border-top: 6px solid #EB776C
        

    &Arrow::before
        content: ''
        transition: border-top .2s
        border: 6px solid transparent
        border-top: 6px solid #000
        position: relative
        top: 16px

    &Clear
        border: none
        position: relative
        cursor: pointer
        margin-right: 10px
        padding-left: 12px
        margin-bottom: -6px
        width: 30px
        top: 0
        height: 30px

        &::before,
        &::after
            content: ''
            position: absolute
            width: 2.5px
            height: 16px
            background: #000

        &::before
            transform: rotate(45deg)
            top: 7px
            left: 13px

        &::after
            transform: rotate(-45deg)
            top: 7px
            left: 13px

    &Error
        border: 2px solid var(--error-color)

    &Control
        max-height: 32px
        min-height: 16px
        padding: 8px 40px 8px 8px
        border-radius: 4px
        transition: all 0.2s
        touch-action: manipulation
        font-size: var(--lg-text)
        min-width: 42px
        display: inline-block
        border: 1px solid rgba(0, 0, 0, 0.15)
        min-width: 100%

        &::placeholder
            opacity: 0

    &Label
        transition: all 0.2s
        touch-action: manipulation
        position: absolute
        height: 20px
        left: 16px
        bottom: 8px
        display: inline-block

        &noBoldLabel
            font-weight: 300 !important

    &ReadOnly *
        cursor: pointer !important

    &ReadOnly &Control:placeholder-shown + &Label
        left: 16px
        font-size: 16px
        font-weight: normal

    &Control:placeholder-shown + &Label
        cursor: text
        white-space: nowrap
        text-overflow: ellipsis
        // transform-origin: bottom
        // transform: translate(32px, 42px) scale(1.1)

    &Control:not(:placeholder-shown) + &Label,
    &Control:focus + &Label
        // transform: translate(-2px, 17px) scale(.9)
        cursor: pointer
        left: 16px
        font-size: 16px

.field
    display: flex
    flex-direction: column-reverse
    transition: all 0.2s
    touch-action: manipulation
    background-color: #fff
    overflow: hidden
    max-height: 64px
    position: relative
    border-radius: 4px

    &.disabled
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF


        &.fieldReadOnly *
            cursor: default !important


        .fieldArrow:before
            border-top: 8px solid rgba(3, 18, 40, 0.5)

        .fieldLabel
            opacity: 0.5

        .fieldControl
            color: rgba(3, 18, 40, 0.5)

    &Action
        position: absolute
        right: 25px
        top: 50%
        transform: translateY(-50%)
        display: flex
        align-items: center

        &Compact
            position: absolute
            right: 25px
            top: 40%
            transform: translateY(-50%)
            display: flex
            align-items: center

    &ArrowBtn
        padding: 0
        border: 0
        transition: transform .2s

        &.active
            transform: rotate(180deg)

            .fieldArrow:before
                border-top: 6px solid #007FFD

            &.landing .fieldArrow:before
                border-top: 6px solid #EB776C

            &.black .fieldArrow::before
                border-top: 6px solid #FFB500

    &Arrow::before
        content: ''
        transition: border-top .2s
        border: 6px solid transparent
        border-top: 6px solid #000
        position: relative
        top: 15px


    &Clear
        border: none
        position: relative
        cursor: pointer
        margin-right: 10px
        // margin-bottom: -2px
        width: 30px
        top: 0
        height: 30px

        &WideField
            margin-right: -15px
        &::before,
        &::after
            content: ''
            position: absolute
            width: 2.5px
            height: 16px
            background: #000

        &::before
            transform: rotate(45deg)
            top: 7px
            left: 13px

        &::after
            transform: rotate(-45deg)
            top: 7px
            left: 13px

    &NoError
        border: 1px solid rgba(0, 0, 0, 0.15)
    &Error
        border: 2px solid var(--error-color)

    &Control
        width: 100%
        max-height: 64px
        min-height: 64px
        border-radius: 4px
        padding: 22px 45px 8px 16px
        transition: all 0.2s
        font-size: var(--lg-text)
        touch-action: manipulation

        // &.free
        //     padding-right: 45px

        &::placeholder
            opacity: 0
        &WideField
            padding: 22px 30px 8px 16px
    &Label
        transition: all 0.2s
        touch-action: manipulation
        font-weight: bold
        position: absolute
        height: 20px

        &noBoldLabel
            font-weight: 300 !important

    &ReadOnly *
        cursor: pointer !important

    &NoEdite *
        cursor: default !important

    &NoEdite &Clear
        display: none !important

    &ReadOnly &Control:placeholder-shown + &Label
        top: 22px
        left: 16px
        font-size: 16px
        font-weight: normal

    &Control:placeholder-shown + &Label
        cursor: text
        white-space: nowrap
        text-overflow: ellipsis
        min-height: 20px
        top: 22px
        left: 16px
        font-size: 16px
        pointer-events: none

    &Control:not(:placeholder-shown) + &Label,
    &Control:focus + &Label
        // transform: translate(-2px, 17px) scale(.9)
        cursor: pointer
        top: 9px
        left: 16px
        font-size: 12px

.symmetrical
    padding: 8px 16px

.admin
    padding-top: 22px

    .field
        overflow: visible

    .fieldControl,
    .fieldLabel
        font-weight: 400
        font-size: 15px
        line-height: 130%
        letter-spacing: 0.1px
        color: #343434

    .fieldControl
        min-height: 34px
        max-height: 34px
        background: #F3F6F9
        border: 0
        border-radius: 8px
        padding: 10.5px 12px
        transition: background .2s

        &:focus
            background: #E8F0FE

    .fieldLabel
        top: 50%
        transform: translateY(-50%)

    .fieldControl:placeholder-shown + .fieldLabel
        top: 50%
        transform: translateY(-50%)

    .fieldControl:not(:placeholder-shown) + .fieldLabel
        top: -10px
        left: 0
        font-size: 12px
        line-height: 130%
        letter-spacing: 0.1px
        color: #909090

    .fieldAction
        right: 14px
        align-items: center

    .fieldArrowBtn
        display: flex

    .fieldClear
        display: flex
        cursor: pointer
        margin-right: 0
        padding: 0
        width: 24px
        height: 24px
        margin-left: -12px

        img
            width: 100%
            height: 100%

        &::before,
        &::after
            content: ''
            display: none

.noAnimateLabel
    padding-top: 0

    .fieldControl:not(:placeholder-shown) + .fieldLabel
        display: none

    .fieldLabel
        top: 50%
        transform: translateY(-50%)

@media (min-width: 1080px)
    .landing .field
        border-radius: 0 !important
        border: 0
        border-right: 1px solid #D9D9D9

        & *
            border-radius: 0 !important
            border: 0

    .landing .field.last
        border-right: 0

@media (max-width: 500px)
    .field
        &Action
            right: 20px

        &Control
            padding-right: 70px

            &.free
                padding-right: 40px

        &Clear
            margin-right: 6px
