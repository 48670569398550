.dropdown

    &Content
        position: absolute
        z-index: 14
        min-width: 203px
        margin-top: 8px
        border-radius: 4px
        padding: 16px
        background-color: #fff
        border: 1px solid rgba(0, 0, 0, 0.15)
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1)
        opacity: 0
        visibility: hidden

        &.active
            opacity: 1
            visibility: visible

        &Fluid
            width: 100%

    &Control
        position: relative
        background-color: #fff
        border-radius: 4px

.contentRight
    right: 0

.compactContent
    position: absolute
    z-index: 30
    border-radius: 0 0 4px 4px
    padding: 16px
    background-color: #fff
    border: 1px solid rgba(0, 0, 0, 0.15)
    border-top: none
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1)
    opacity: 0
    visibility: hidden
    left: 0

    &.active
        opacity: 1
        visibility: visible

.skeleton
    width: 100%
    height: 62px
    background-color: #fff

    &Control
        position: relative

.picker
    background: #F6F6F6
    border-radius: 8px
    height: 100%
    display: flex
    align-items: center
    cursor: pointer
    border: 0
    padding: 5px 12px

    & > img
        margin-left: 11px

    span
        color: var(--primary-color)
        font-weight: 500
        margin: 0 5px

    button
        display: flex
        border: 0
        padding: 0


@media (max-width: 550px)
    .dropdownContent
        min-width: 280px
