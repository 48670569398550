.header
    &Block
        padding-top: 64px
        display: flex
        flex-direction: column
        gap: 16px

.instruction
    &Block
        margin-top: 48px
        padding: 48px
        background: #F3F6F9
        border-radius: 16px
        max-width: 68%

.instStar
    padding-bottom: 13px
    

.socials
 display: flex
 flex-direction: row
 text-decoration: none

.social
    display: flex
    padding-top: 28px
    align-items: center
    column-gap: 32px
    //justify-content: center

@media (max-width: 550px)
    .instruction
        &Block
            padding: 16px
            max-width: 100%
    .social
        display: flex
        padding-top: 28px
        align-items: center
        column-gap: 32px
        flex-wrap: wrap
        row-gap: 30px

         

