.button
    border: none
    color: var(--primary-color)
    background-color: var(--accent-color)
    font-weight: bold
    border-radius: 4px
    padding: 8px 16px
    cursor: pointer
    text-align: center
    transition: all 0.2s

    &:disabled, &.disabled
        background-color: var(--border-color)
        color: #fff
        cursor: not-allowed
        pointer-events: none


// bg color

.primary
    background-color: var(--primary-color)
    color: #fff

.refreshCabin
    background-color: #FFFFFF
    padding: 12px 20px
    font-size: 16px
    line-height: 20px
    font-weight: 700
    color: #007FFD

.primaryBf
    background-color: #FFB500 !important
    color: #000000 !important

.primaryNewYear
    background-color: #17C613
    color: #fff

.blackFriday
    color: #FFB500
    background-color: transparent
    padding-left: 0
    padding-right: 0

.promoSubmit
    padding: 4px 8px
    font-size: 14px
    font-style: normal
    font-weight: 700
    line-height: 20px
    max-width: 106px
    //color: var(--primary-color)

.seaRequest
    width: 157px
    height: 64px
    border-radius: 4px
    background-color: #ffffff
    color: #007FFD
    padding: 16px 24px

.blog
    width: 66px
    height: 16px
    background-color: var(--shadow-primary-color)
    font-size: 14px
    padding: 8px 16px
    color: var(--primary-color)

.buttonDisаble
    color: var(--shadow-primary-color)

.podpis-lg
    font-size: 16px
    width: 157px
    height: 64px
    color: var(--primary-color)
    background-color: var(--shadow-primary-color)

.partner
    background-color: #EF3959
    color: #FFFFFF
    border-radius: 72px
    border: none
    font-size: 22px
    padding: 16px 32px

.headerBtn
    background-color: #EF3959
    color: #FFFFFF
    border-radius: 72px
    box-shadow: 0px 0px 40px rgba(239, 57, 89, 0.4)
    padding: 12px 24px


.text
    background-color: transparent
    padding-left: 0
    padding-right: 0

.textPartner
    background-color: transparent
    color: #EF3959
    padding-left: 0
    padding-right: 0
    // padding-bottom: 32px

.buttonModalData
    font-size: 18px
    color: #fff
    background-color: var(--primary-color)
    width: 100%
    padding: 20px

.textResult
    background-color: #fff
    color: var(--primary-color)
    border: 1px solid var(--primary-color)
    padding-top: 15px
    padding-bottom: 15px

.textcResultBf
    background-color: #141517 !important
    color: #FFB500 !important
    border: 1px solid #000 !important
    padding-top: 15px
    padding-bottom: 15px

.textDelete
    color: #FD2249
    padding: 4px 8px

.textEdit
    font-style: normal
    font-weight: 400
    font-size: var(--lg-text)
    line-height: 30px
    color: var(--primary-color)
    background: #FFF

.reviewsButton
    background-color: #fff
    color: var(--primary-color)
    padding: 4px 8px
    border-radius: 4px

.white
    background-color: #fff
    color: var(--primary-color)
    padding-left: 0

.danger
    color: var(--error-color)
    background-color: #fff

.danger-accent
    padding: 6px
    color: var(--error-color)
    background-color: var(--accent-color)

.danger-white
    color: var(--error-color) !important
    background-color: #fff !important

// 'mini-tabs',
// 'mini-tabs-active',
.mini-tabs
    background-color: var(--accent-color)
    color: var(--primary-color)
    border-radius: 12px

    &-active
        background-color: var(--primary-color)
        color: #fff
        border-radius: 12px

.fullWidth
    width: 100%

.topGap
    margin-top: 16px

.exc
    background-color: #fff
    color: var(--primary-color)
    font-size: 14px
    padding: 4px 8px

.landingSale
    background-color: var(--primary-color)
    color: #fff
    font-size: 16px

.landingAdvice
    background-color: #EB776C
    color: #fff
    font-size: 16px

.uploader
    font-weight: 700
    font-size: var(--md-text)
    line-height: 20px
    color: var(--primary-color)
    background: inherit
    padding: 8px

.scheme
    background-color: #F3F6F9
    color: var(--primary-color)
    font-size: 14px
    padding: 4px 8px

.subscribe
    background: #F3F6F9
    border-radius: 4px
    color: var(--primary-color)
    font-size: 18px
    line-height: 24px
    padding: 12px

.uploaderButton
    font-weight: 700
    font-size: var(--md-text)
    line-height: 20px
    color: #818894
    background-color: #F3F6F9
    border: 1px dashed #818894
    padding: 20px
    border-radius: 8px
    display: flex
    justify-content: center
    align-items: center

    &:hover
        border: 1px dashed black
        color: black

.sendReview
    background-color: var(--primary-color)
    color: #FFFFFF
    padding: 20px 78px 20px 78px

.actualBlockDate
    padding: 12px 20px 12px 20px
    background-color: #007FFD
    color: #fff
    border-radius: 4px
    width: 272px

.actualBlockDir
    padding: 12px 20px 12px 20px
    background-color: #fff
    color: #007FFD
    border-radius: 4px
    width: 272px



.admin
    padding: 11px
    background: #007FFD
    border-radius: 8px
    color: #fff
    font-family: 'Roboto', sans-serif
    font-weight: 500
    font-size: 15px
    line-height: 18px
    letter-spacing: 0.15px
    width: 100%
    transition: background .2s

    &:disabled
        color: #747474
        background: #E7E7E7

    &:not(:disabled):hover
        background: #093EE8

// sizes

// .md
//     padding: 8px 16px

.sm
    padding: 4px 8px

.lg
    padding: 16px
    font-size: var(--vlg-text)

.lgSpecial
    font-size: var(--vlg-text)

.bold
    font-weight: bold !important
    padding: 4px 8px
    line-height: normal !important

.very-large
    padding: 10px 20px
    font-size: var(--mlg-text)
    font-weight: bold

.subscription
    padding: 24px
    background-color: rgba(24, 70, 143, 0.05)
    border-radius: 4px

.semi-lg
    font-size: var(--vlg-text)
    padding: 12px 20px

.subscriptionFooter
    font-size: 16px
    padding: 19.5px 18px
    color: var(--primary-color)
    background-color: var(--shadow-primary-color)

.subscriptionLitres
    font-size: 16px
    padding: 20px 12px
    width: 224px
    color: var(--primary-color)
    background: #FFF

.subscriptionLitresMob
    font-size: 16px
    padding: 24px 16px
    width: 312px
    color: var(--primary-color)
    background: #FFF
