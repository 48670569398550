.wrapper
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 80px

.secondHeader
    max-width: 630px
    text-align: center
    margin: 0 auto 16px

.header
    max-width: 430px
    text-align: center
    margin: 0 auto 16px

    & > *
        line-height: 24px

.body
    display: grid
    grid-template-columns: 290px 290px 158px
    grid-gap: 16px
    align-items: start

    &.season
        grid-template-columns: 290px 290px 192px

    button
        padding: 18.5px 16px

.headerText
    padding-top: 16px

.agreement
    font-size: 14px
    line-height: 20px
    margin: 16px auto 32px
    max-width: 608px
    text-align: center
    color: rgba(3, 18, 40, 0.5)

.success
    margin-bottom: 32px

.error
    color: red
    text-align: center
    margin: 0 0 16px
    font-size: 16px

@media (max-width: 850px)
    .wrapper
        display: block

    .header
        max-width: 500px

    .body
        grid-template-columns: auto

        & > *
            width: 100%

@media (max-width: 450px)
    .error
        font-size: 14px
