.datePicker
    width: 530px

.arrow
    width: 15px
    height: 0
    display: block
    border-top: 7px solid transparent !important
    border-right: 14px solid var(--primary-color) !important
    border-bottom: 7px solid transparent !important
    transform: translateX(15%)

    &.landing
        border-right: 14px solid #EB776C !important

    &.admin
        border-top: 0 !important
        border-right: 0 !important
        border-bottom: 0 !important
        width: auto
        height: auto

.arrowBf
    width: 15px
    height: 0
    display: block
    border-top: 7px solid transparent !important
    border-right: 14px solid #FFB500 !important
    border-bottom: 7px solid transparent !important
    transform: translateX(15%)

@media (max-width: 600px)
    .datePicker
        width: 286px
