.wrapper
    width: 100%
    height: 108px
    display: flex
    justify-content: space-around
    align-items: center
    position: fixed
    bottom: 0
    background-color: #ffffff
    box-shadow: 0 -8px 20px 0 #0000000F
    padding: 20px
    z-index: 200
    &Inside
        width: 100%
        display: flex
        justify-content: space-between
        align-items: center

.header
    font-size: 18px
    font-weight: 700
    line-height: 24px
    color: #007FFD

.bodyText
    font-size: 14px
    line-height: 20px
    font-weight: 400
    color: #282A2E

    &Link
        text-decoration: none
        color: #007FFD

.closeButton
    display: inline-block
    width: 34px
    height: 34px
    background-position: center
    background-size: 50% 2px, 2px 50%
    background-repeat: no-repeat
    background: linear-gradient(#0281FF,#0281FF),linear-gradient(#0281FF,#0281FF),#FFFF
    background-position: center
    background-size: 50% 2px, 2px 50%
    background-repeat: no-repeat
    transform: rotate(45deg)
    flex-shrink: 0
    border: none
    cursor: pointer

    &Aside
        display: flex
        align-items: start

@media (max-width: 768px)

    .wrapper
        height: 198px
        padding: 0

        &Inside
            align-items: start
