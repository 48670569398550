.wrapper
    text-align: center

.wrapperBf
    width: 356px
    height: 300px
    border-radius: 16px
    display: flex
    text-align: center
    background-color: rgba(255, 255, 255, 0.3)
    flex-direction: column
    justify-content: center

    &Content
        display: flex
        flex-direction: column
        align-items: center

.subscribeSuccess
    display: flex
    justify-content: center

.icon
    width: 120px
    height: 120px
    display: flex
    align-items: center
    text-align: center
    justify-content: center
    background-color: rgba(255, 181, 0, 1)
    border-radius: 50%

.text
    max-width: 300px
    margin: 8px auto 0
