.label
    display: block
    position: relative
    margin-bottom: 16px

.icon
    position: absolute
    left: 14px
    top: 50%
    transform: translateY(-50%)

.input
    display: block
    width: 100%
    background: #FFFFFF
    border: 1px solid rgba(24, 70, 143, 0.5)
    box-sizing: border-box
    border-radius: 4px
    padding: 0 8px 0 40px

// Sizes

.sm
    height: 32px

.md
    height: 48px
