.wrapper
    display: flex
    align-items: center
    justify-content: space-between
    margin: 0 -8px -8px 0

    &:not(:first-child)
        margin-top: 8px

    p
        font-weight: 600
        margin: 0
        font-size: 14px
        line-height: 16px
