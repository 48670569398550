.checkboxes > *:not(:last-child)
    margin-bottom: 16px

.chek
    display: inline-block
    width: 7px
    height: 11px
    margin: -3px 10px 3px
    border: solid #007FFD
    border-width: 0 3px 3px 0
    transform: rotate( 45deg)

.element
    display: flex
    justify-content: space-between
    align-items: center
