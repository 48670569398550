.react-calendar
    border-radius: 4px
    width: 350px
    max-width: 100%
    background: white
    font-family: 'Inter', Arial, Helvetica, sans-serif
    line-height: 1.125em

.react-calendar--doubleView
    width: 700px

    .react-calendar__viewContainer
        display: flex
        margin: -0.5em

        > *
            width: 50%
            margin: 0.5em

.react-calendar
    -moz-box-sizing: border-box
    -webkit-box-sizing: border-box
    box-sizing: border-box

    *
        -moz-box-sizing: border-box
        -webkit-box-sizing: border-box
        box-sizing: border-box

        &:before, &:after
            -moz-box-sizing: border-box
            -webkit-box-sizing: border-box
            box-sizing: border-box

.react-calendar__navigation__label__labelText
    font-size: 14px
    line-height: 20px
    font-weight: bold
    color: #007FFD

.react-calendar button
    margin: 0
    border: 0
    outline: none

    &:enabled:hover
        cursor: pointer

.react-calendar__navigation
    display: flex
    height: 44px

    button
        min-width: 44px
        background: none

        &:enabled
            &:hover, &:focus
                background-color: #f3f6f9

        &[disabled]
            background-color: #fff

.react-calendar__month-view__weekdays
    text-align: center
    text-transform: uppercase
    font-weight: bold
    font-size: 12px

.react-calendar__month-view__weekdays__weekday
    padding: 0.5em
    margin-left: 1px
    margin-bottom: 6px

    *
        display: flex
        border-bottom: none !important
        appearance: none !important
        color: #818894
        font-size: 10px !important
        line-height: 16px
        height: 6.8px

.react-calendar__month-view__weekNumbers .react-calendar__tile
    display: flex
    align-items: center
    justify-content: center
    font-size: 14px
    font-weight: bold
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75)

.react-calendar__month-view__days__day--weekend
    color: #d10000

.react-calendar__month-view__days__day--neighboringMonth
    color: #818894 !important

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile
    padding: 2em 0.5em

.react-calendar__tile
    max-width: 100%
    color: #007FFD
    text-align: center
    padding: 0.75em 0.5em
    font-weight: bold
    background: none
    font-size: 14px

    &:disabled
        color: #818894

    &:enabled
        &:hover, &:focus
            color: #007FFD

.react-calendar__tile--range
    color: #007FFD

.react-calendar__tile:enabled
    &:hover, &:focus
        color: #007FFD

.react-calendar__tile--rangeStart, .react-calendar__tile--hoverStart
    border-top-right-radius: 0 !important
    border-bottom-right-radius: 0 !important

.react-calendar__tile--range:not(.react-calendar__tile--rangeStart)
    border-radius: 0

.react-calendar__tile--hoverEnd
    border-top-right-radius: 50px
    border-bottom-right-radius: 50px

.react-calendar__tile--rangeEnd
    border-top-right-radius: 50px !important
    border-bottom-right-radius: 50px !important

.react-calendar__tile--hasActive
    background: #007FFD
    color: #fff
    border-radius: 50px

    &:enabled
        &:hover, &:focus
            background: #007FFD
            color: #fff

.react-calendar__tile--active
    background: #007FFD
    border-radius: 50px
    color: white

.react-calendar__month-view__days__day--neighboringMonth
    visibility: hidden

.react-calendar__tile--active:enabled
    &:hover, &:focus
        background: #1087ff
        color: #fff !important

.react-calendar--selectRange .react-calendar__tile--hover
    background-color: #f3f6f9

.react-calendar__tile--hover
    color: #007FFD

.react-calendar__tile--hoverEnd
    color: #007FFD !important

.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button, .react-calendar__navigation__label__divider
    display: none

.react-calendar__navigation__label
    display: flex
    align-items: center
    width: 100%
    cursor: auto
    padding-top: 4px !important
    justify-content: space-between

// ------ Типы календарей ------ //
.search-calendar
    border: 1px solid rgba(0, 0, 0, 0.15)
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1)
    border-radius: 4px

    .react-calendar__navigation button
        min-width: 30px

    .react-calendar__navigation__next-button
        transform: rotate(180deg)

    .react-calendar__navigation__prev-button:disabled span,
    .react-calendar__navigation__next-button:disabled span
        opacity: .4

    .react-calendar__navigation__label
        justify-content: space-around

    .react-calendar__month-view__weekdays__weekday *
        height: initial
        justify-content: center

    .react-calendar__month-view__weekdays__weekday
        margin-left: initial

    &.landing-calendar
        $red: #EB776C

        .react-calendar__navigation__label__labelText,
        .react-calendar__tile:not(:disabled)
            color: $red

        .react-calendar__tile--hoverEnd
            color: $red !important

        .react-calendar--selectRange,
        .react-calendar__tile--hover
            background: #ee2411

        .react-calendar__tile--active
            background: $red
            color: #fff !important

            &:hover,
            &:focus
                background: #EB776C

    &.promotion-calendar
        $red: #FFB500

        .react-calendar__navigation__label__labelText,
        .react-calendar__tile:not(:disabled)
            color: $red

        .react-calendar__tile--hoverEnd
            color: $red !important

        .react-calendar--selectRange,
        .react-calendar__tile--hover
            background: #f8dd99

        .react-calendar__tile--active
            background: $red
            color: #000000 !important

            &:hover,
            &:focus
                background: #ffc531

.departures-calendar
    border: 1px solid rgba(0, 0, 0, 0.15)
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1)
    border-radius: 4px
    padding: 8pxа

    .react-calendar__navigation button
        min-width: 30px

    .react-calendar__navigation__next-button
        transform: rotate(180deg)

    .react-calendar__navigation__prev-button:disabled span,
    .react-calendar__navigation__next-button:disabled span
        opacity: .4

    .react-calendar__navigation__label
        justify-content: center

    .react-calendar__month-view__weekdays__weekday *
        height: initial
        justify-content: center

    .react-calendar__month-view__weekdays__weekday
        margin-left: initial

    &.landing-calendar
        $red: #EB776C

        .react-calendar__navigation__label__labelText,
        .react-calendar__tile:not(:disabled)
            color: $red

        .react-calendar__tile--hoverEnd
            color: $red !important

        .react-calendar--selectRange,
        .react-calendar__tile--hover
            background: #FBE5E3

        .react-calendar__tile--active
            background: $red
            color: #fff !important

            &:hover,
            &:focus
                background: #EB776C

    &.promotion-calendar
        $red: #FFB500

        .react-calendar__navigation__label__labelText,
        .react-calendar__tile:not(:disabled)
            color: $red

        .react-calendar__tile--hoverEnd
            color: $red !important

        .react-calendar--selectRange,
        .react-calendar__tile--hover
            background: #f8dd99

        .react-calendar__tile--active
            background: $red
            color: #000000 !important

            &:hover,
            &:focus
                background: #ffc531

.admin-calendar
    $border-color: #F3F6F9

    .react-calendar__navigation__label
        justify-content: center

    .react-calendar__navigation__label__labelText
        font-family: 'Roboto', sans-serif
        font-weight: 500
        font-size: 15px
        line-height: 18px
        letter-spacing: 0.01em
        padding: 0 8px
        color: #000

    .react-calendar__month-view__weekdays__weekday
        flex: none
        padding: 0
        margin-left: 0
        margin-bottom: 0
        width: 36px
        height: 36px
        border: 1px solid $border-color
        display: flex
        align-items: center
        justify-content: center
        border-right: 0
        color: rgba(#343434, .2)

    .react-calendar__month-view__weekdays__weekday:last-child
        border-right: 1px solid $border-color

    .react-calendar__month-view__weekdays__weekday abbr[title]
        text-decoration: none

    .react-calendar__month-view__weekdays__weekday *
        height: auto
        font-family: 'Roboto', sans-serif
        font-weight: 700
        font-size: 13px
        line-height: 15px
        letter-spacing: 0.01em

    .react-calendar__tile
        width: 34px
        height: 34px
        padding: 0
        border-bottom: 1px solid $border-color
        border-left: 1px solid $border-color
        font-family: 'Roboto', sans-serif
        font-weight: 400
        font-size: 13px
        line-height: 15px
        letter-spacing: 0.01em
        color: #000

    .react-calendar__tile:disabled
        color: #747474
        background: #E7E7E7

    .react-calendar__tile:nth-child(7n)
        border-right: 1px solid $border-color

    .react-calendar__month-view__days__day--neighboringMonth
        visibility: visible
        background: #F3F6F9

    .react-calendar__tile--hover
        background: #CEDDF4

@media (max-width: 600px)
    .react-calendar__viewContainer
        display: flex !important
        flex-direction: column !important

    .react-calendar
         line-height: 1.0em

    .react-calendar--doubleView .react-calendar__viewContainer > *
        width: 90%
