.wrapper
    flex: 1 0 auto
    margin-top: 78px
    &Banner
        flex: 1 0 auto
        margin-top: 153px
.innerWrapper
    width: 100%

.innerWrapperBlog
    width: 100%
    max-width: 816px
    margin: 0 auto

.innerWrapperBlackFriday
    width: 100%
    background-color: black