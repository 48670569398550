.searchInfo
    width: 100%
    background-color: #F3F6F9
    padding-left: 16px
    padding-bottom: 16px
    display: block
    position: fixed
    top: 105px
    left: 0
    z-index: 15

    h1
        font-size: 20px
        margin: 0
    span
        font-size: 28px
        display: inline-block
        margin: 8px
    p
        margin-top: 4px
        margin-bottom: 0
        font-size: 16px
        line-height: 20px

.arrowBf
    width: 17px
    height: 17px

.infoDesktop
    width: 100%
    max-width: 1070px
    padding: 24px 16px
    background-color: #F3F6F9
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    column-gap: 16px
    position: fixed
    top: 78px
    left: 0
    right: 0
    margin: 0 auto
    z-index: 15
    cursor: pointer

    .arrow
        transform: rotate(90deg)
        width: 9px
        height: 17px
        //margin: 0 19px 0 20px

    h1
        font-size: 16px
        line-height: 24px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        margin: 0
        //margin: 24px 16px 24px 0
    p
        font-size: 16px
        line-height: 24px
        //margin: 24px 16px 24px auto
        min-width: max-content
        margin: 0
    .button
        display: none

.infoDesktopBf
    width: 100%
    max-width: 1070px
    padding: 24px 16px
    background-color: #141517 !important
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    column-gap: 16px
    position: fixed
    top: 78px
    left: 0
    right: 0
    margin: 0 auto
    z-index: 15
    cursor: pointer

.iconWrapper
    display: flex
    align-items: center
    justify-content: space-between

@media (max-width: 1199px)
    .infoDesktop
        display: none

@media (max-width: 380px)
     .searchInfo
         h1
             font-size: 16px
             line-height: 18px
         p
             font-size: 14px
             line-height: 20px
     .button
         font-size: 14px
         line-height: 20px
